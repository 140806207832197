import { GetStaticProps, GetStaticPropsContext } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { createClient } from "../../prismicio";
import Home from "./home";

export const getStaticProps: GetStaticProps = async ({
  locale,
  previewData,
}: GetStaticPropsContext) => {
  const client = createClient({ previewData });
  const [pageProd, pageStaging, segmentProd, segmentStaging] =
    await Promise.all([
      client.getSingle("homepage_eco"),
      client.getSingle("homepage_eco_staging"),
      client.getSingle("page_accueil"),
      client.getSingle("page_accueil_staging"),
    ]);

  return {
    props: {
      ...(await serverSideTranslations(locale as string, [
        "common",
        "validation",
        "header",
        "footer",
      ])),
      pageProd,
      pageStaging,
      segmentProd,
      segmentStaging,
    },
  };
};

export default Home;
