import { GetStaticProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { Container } from "@components/ui";
import { MainLayout } from "@layouts/MainLayout/MainLayout";
import { SliceZone } from "@prismicio/react";
import { Content } from "@prismicio/client";
import { usePromotionFooter } from "@middleware/hooks/usePromotionFooter";
import { useStagingMode } from "@middleware/hooks";
import { createClient } from "../../../prismicio";
import { components } from "../../../slices";

type PageProps = {
  pageProd: Content.HomepageEcoDocument;
  pageStaging: Content.HomepageEcoStagingDocument;
  segmentProd: Content.PageAccueilDocument;
  segmentStaging: Content.PageAccueilStagingDocument;
};

export const getStaticProps: GetStaticProps = async ({
  locale,
  previewData,
}) => {
  const client = createClient({ previewData });

  const [pageProd, pageStaging, segmentProd, segmentStaging] =
    await Promise.all([
      client.getSingle("homepage_eco"),
      client.getSingle("homepage_eco_staging"),
      client.getSingle("page_accueil"),
      client.getSingle("page_accueil_staging"),
    ]);

  return {
    props: {
      ...(await serverSideTranslations(locale as string, [
        "common",
        "header",
        "footer",
      ])),
      pageProd,
      pageStaging,
      segmentProd,
      segmentStaging,
    },
  };
};

const HomePage = ({
  pageProd,
  pageStaging,
  segmentProd,
  segmentStaging,
}: PageProps) => {
  const { isStagingMode } = useStagingMode();
  const { promotionFooterSlices } = usePromotionFooter(
    isStagingMode ? segmentStaging : segmentProd
  );

  if (isStagingMode) {
    return (
      <Container className="page-cms">
        <SliceZone slices={pageStaging.data.slices} components={components} />
        <SliceZone slices={promotionFooterSlices} components={components} />
      </Container>
    );
  }

  return (
    <Container className="page-cms">
      <SliceZone slices={pageProd.data.slices} components={components} />
      <SliceZone slices={promotionFooterSlices} components={components} />
    </Container>
  );
};

HomePage.layout = MainLayout;
export default HomePage;
